// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cast-and-crew-js": () => import("/opt/build/repo/src/pages/cast-and-crew.js" /* webpackChunkName: "component---src-pages-cast-and-crew-js" */),
  "component---src-pages-charity-partners-js": () => import("/opt/build/repo/src/pages/charity-partners.js" /* webpackChunkName: "component---src-pages-charity-partners-js" */),
  "component---src-pages-comp-terms-and-conditions-js": () => import("/opt/build/repo/src/pages/comp-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-comp-terms-and-conditions-js" */),
  "component---src-pages-competition-js": () => import("/opt/build/repo/src/pages/competition.js" /* webpackChunkName: "component---src-pages-competition-js" */),
  "component---src-pages-follow-us-js": () => import("/opt/build/repo/src/pages/follow-us.js" /* webpackChunkName: "component---src-pages-follow-us-js" */),
  "component---src-pages-host-a-screening-js": () => import("/opt/build/repo/src/pages/host-a-screening.js" /* webpackChunkName: "component---src-pages-host-a-screening-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-release-js": () => import("/opt/build/repo/src/pages/international-release.js" /* webpackChunkName: "component---src-pages-international-release-js" */),
  "component---src-pages-merchandise-js": () => import("/opt/build/repo/src/pages/merchandise.js" /* webpackChunkName: "component---src-pages-merchandise-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/build/repo/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-all-rounder-js": () => import("/opt/build/repo/src/pages/thank-you-all-rounder.js" /* webpackChunkName: "component---src-pages-thank-you-all-rounder-js" */),
  "component---src-pages-thank-you-batter-js": () => import("/opt/build/repo/src/pages/thank-you-batter.js" /* webpackChunkName: "component---src-pages-thank-you-batter-js" */),
  "component---src-pages-thank-you-bowler-js": () => import("/opt/build/repo/src/pages/thank-you-bowler.js" /* webpackChunkName: "component---src-pages-thank-you-bowler-js" */),
  "component---src-pages-where-to-watch-js": () => import("/opt/build/repo/src/pages/where-to-watch.js" /* webpackChunkName: "component---src-pages-where-to-watch-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

